/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    selectedMembershipId: '',
    selectedProgramId: '',
    isLoaSelected: false,
  },
  reducers: {
    setSelectedMembershipId: (state, action) => {
      state.selectedMembershipId = action.payload;
    },
    setSelectedProgramId: (state, action) => {
      state.selectedProgramId = action.payload;
    },
    setIsLoaSelected: (state, action) => {
      state.isLoaSelected = action.payload;
    },
    clearState: () => {
      // nothing to do here, it is handled by the root reducer in the store
    },
  },
});

export const {
  setSelectedMembershipId,
  setSelectedProgramId,
  setIsLoaSelected, clearState,
} = sessionSlice.actions;
export default sessionSlice.reducer;
