import { diffFromDates, diffFromToday } from '@/myphoenix/utils/date-time-functions';
import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { Course, CoursesByRange, CourseSkills } from '@/types/courseInfoData';

export const RANGE = {
  DEFAULT: { min: -455, max: 180 },
  ACADEMIC_PLAN: { min: -7305, max: 1826 },
};

export const augmentSectionsFromMemberships = (
  memberships: StudentCourseMembership[],
  sections: Course[],
): Course[] => sections.map((course) => {
  const membership = memberships.find((m) => m.sourceId === course.id);
  return {
    ...course,
    membershipId: membership?.id,
    sectionId: membership?.sourceId,
    statusSubCode: membership?.statusSubCode,
    startDate: membership?.startDate,
    endDate: membership?.endDate,
    grade: membership?.grade,
  };
});

/**
 * Turns a range's min startdate and max startdate into an id
 *
 * @param range
 * @returns {string}
 */
export const getIdForRange = (range = RANGE.DEFAULT) => (`${range.min}|${range.max}`);

/**
 * Grabs the specified range object from state OR returns an empty range
 *
 * @param courses
 * @param range
 * @returns {*|{membershipIds: [], status: string}}
 */
export const getRangeInfoFromCourses = (
  courses: CoursesByRange,
  range = RANGE.DEFAULT,
) => (courses?.byRangeId?.[getIdForRange(range)] || {
  allMembershipIds: [],
  currentMembershipIds: [],
  futureMembershipIds: [],
  pastMembershipIds: [],
});

export const getEnrolledCourseMemberships = (
  memberships: StudentCourseMembership[],
) => memberships.filter((membership) => (
  membership.statusSubCode === 'CO' || membership.statusSubCode === 'EN' || membership.statusSubCode === 'SC'
));

export const getEnrolledCourses = (
  courses: Course[],
) => courses.filter((course) => (
  course.statusSubCode === 'CO' || course.statusSubCode === 'EN' || course.statusSubCode === 'SC'
));

export const getDroppedCourseMemberships = (
  memberships: StudentCourseMembership[],
) => memberships.filter((membership) => (
  membership.statusSubCode === 'TA'
));

export const getDroppedCourses = (
  courses: Course[],
) => courses.filter((course) => (
  course.statusSubCode === 'TA'
));

export const getScheduledCourseMemberships = (
  memberships: StudentCourseMembership[],
) => memberships.filter((membership) => (
  membership.statusSubCode === 'SC'
));

export const getScheduledCourses = (
  courses: Course[],
) => courses.filter((course) => (
  course.statusSubCode === 'SC'
));

export const getCurrentCourseMemberships = (
  memberships: StudentCourseMembership[],
) => (
  memberships?.filter((membership) => {
    if (!membership?.startDate || !membership?.endDate) return false;
    const startDays = diffFromToday(membership.startDate);
    const endDays = diffFromToday(membership.endDate);
    return startDays < 0 && endDays > 0;
  })
);

export const getCurrentCourses = (
  courses: Course[],
) => (
  courses?.filter((course) => {
    if (!course?.startDate || !course?.endDate) return false;
    const startDays = diffFromToday(course.startDate);
    const endDays = diffFromToday(course.endDate);
    return startDays < 0 && endDays > 0;
  })
);

export const getFutureCourseMemberships = (
  memberships: StudentCourseMembership[],
  startCutoff: number = 7,
) => (
  memberships?.filter((membership) => {
    if (!membership?.startDate) return false;
    const startDays = diffFromToday(membership.startDate);
    return startDays > 0 && (startCutoff === 0 || startDays <= startCutoff);
  })
);

export const getFutureCourses = (
  courses: Course[],
  startCutoff: number = 7,
) => (
  courses.filter((course) => {
    if (!course?.startDate) return false;
    const startDays = diffFromToday(course.startDate);
    return startDays > 0 && (startCutoff === 0 || startDays <= startCutoff);
  })
);

export const getPastCourseMemberships = (
  memberships: StudentCourseMembership[],
) => (
  memberships.filter((membership) => {
    if (!membership?.endDate) return false;
    const endDays = diffFromToday(membership.endDate);
    return endDays < 0;
  })
);

export const getPastCourses = (
  courses: Course[],
) => (
  courses.filter((course) => {
    if (!course?.endDate) return false;
    const endDays = diffFromToday(course.endDate);
    return endDays < 0;
  })
);

export const sortByMembershipStartDate = (
  memberships: StudentCourseMembership[],
  // using slice().sort to avoid modifying the original array
) => memberships?.slice().sort((a, b) => diffFromDates(a.startDate, b.startDate));

export const sortByStartDate = (
  courses: Course[],
  // using slice().sort to avoid modifying the original array
) => courses?.slice().sort((a, b) => diffFromDates(a.startDate, b.startDate));

export const sortByMembershipEndDate = (
  memberships: StudentCourseMembership[],
  // using slice().sort to avoid modifying the original array
) => memberships?.slice().sort((a, b) => diffFromDates(a.endDate, b.endDate));

export const sortByEndDate = (
  courses: Course[],
  // using slice().sort to avoid modifying the original array
) => courses?.slice().sort((a, b) => diffFromDates(a.endDate, b.endDate));

export const getPreviousCourses = (
  courses: Course[],
) => {
  const previousCourses = (course: Course) => {
    const daysSinceCourse = diffFromToday(course.endDate);
    return (
      (daysSinceCourse < -1
      && daysSinceCourse >= -14
      && course.statusSubCode === 'CO')
      || ['IX', 'I', 'IP'].includes(course.grade)
    );
  };
  return sortByEndDate(courses.filter((course) => previousCourses(course)));
};

export const getMostRecentPastCourseMembership = (
  pastCourseMemberships: StudentCourseMembership[],
) => {
  const sorted = sortByMembershipEndDate(pastCourseMemberships);
  return sorted[sorted.length - 1];
};

export const getMostRecentPastCourse = (
  pastCourses: Course[],
) => {
  const sorted = sortByEndDate(pastCourses);
  return sorted[sorted.length - 1];
};

export const convertClassRostNumToMembershipId = (item: string) => `OSIRIS:${item}`;

export const aggregateCourseSkills = (skillsForCourses: CourseSkills[])
: { [key: string]: { skill: string }[] } => {
  if (skillsForCourses?.length) {
    return skillsForCourses.reduce((prev, course: CourseSkills) => {
      const skills = course?.objectives?.reduce((prevSkills, objective) => (
        [
          ...prevSkills,
          ...objective.courseSkill
            ? objective.courseSkill.map((courseSkill) => ({ skill: courseSkill.title }))
            : [],
        ]
      ), []);
      return {
        ...prev,
        [course.templateId]: skills,
      };
    }, {});
  }

  return {};
};
