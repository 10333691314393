import { useEffect, useState } from 'react';
import { useDevCycleClient } from '@devcycle/react-client-sdk';

export default function DevCycle({ personId }: { personId: string }): null {
  const dvcClient = useDevCycleClient();
  const [dvcPersonId, setDvcPersonId] = useState<string>(null);

  useEffect(() => {
    if (personId && personId !== dvcPersonId) {
      setDvcPersonId(personId);
      dvcClient.identifyUser({
        user_id: personId,
        customData: {
          personId,
        },
      });
    }
  }, [personId, dvcPersonId, dvcClient]);

  return null;
}
