import { baseApi2 } from '@/store/queries';
import { personEndpoints } from '@/store/domains/resources/persons';
import type { Information, Military, PersonName, Person, UserAttributesResponse, Avatar } from '@/types/api/persons';

export const personsApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getPersonById: build.query<Person, { personId: string }>(
      { query: ({ personId }) => ({ url: personEndpoints.getPersonByPersonId(personId), method: 'GET' }) },
    ),
    getOfficialName: build.query<PersonName, { personId: string }>(
      {
        query: ({ personId }) => ({ url: personEndpoints.getNamesByPersonId(personId), method: 'GET' }),
        transformResponse: (returnValue: PersonName[]) => returnValue.find((name) => name?.type?.toLowerCase() === 'official'),
      },
    ),
    getNamesByPersonIds: build.query<PersonName[], {
      personIds: string[],
    }>({
      async queryFn(
        { personIds },
        api,
        extraOptions,
        baseQuery,
      ) {
        if (!personIds || personIds.length < 1) {
          return { data: [] };
        }

        let error;
        const personNamePromises = personIds.map(async (personId: string) => {
          const response = await baseQuery({ url: personEndpoints.getNamesByPersonId(personId), method: 'GET' });
          if (response.error) {
            error = response.error;
          }
          return response.data;
        });

        const personNamesData = await Promise.all(personNamePromises);

        if (error) {
          return { error };
        }

        return { data: personNamesData.flatMap((data) => data) };
      },
    }),
    getAvatar: build.query<Avatar, { personId: string }>(
      {
        query: ({ personId }) => ({ url: personEndpoints.getAvatarByPersonId(personId), method: 'GET' }),
      },
    ),
    getDemographicsByPersonId: build.query<Information, { personId: string }>(
      {
        query: ({ personId }) => ({ url: personEndpoints.getDemographicsByPersonId(personId), method: 'GET' }),
      },
    ),
    getUserAttributesByPersonId: build.query<UserAttributesResponse, { personId: string }>(
      {
        query: ({ personId }) => ({
          url: personEndpoints.getUserAttributesByPersonId(personId), method: 'GET',
        }),
      },
    ),
    getMilitaryStatus: build.query<Military, { personId: string }>(
      {
        query: ({ personId }) => ({ url: personEndpoints.getMilitaryStatus(personId), method: 'GET' }),
      },
    ),
  }),
});

export const {
  useGetPersonByIdQuery,
  useGetOfficialNameQuery,
  useGetNamesByPersonIdsQuery,
  useGetAvatarQuery,
  useGetDemographicsByPersonIdQuery,
  useGetUserAttributesByPersonIdQuery,
  useGetMilitaryStatusQuery,
} = personsApi;
